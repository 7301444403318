.card {
  display: flex;
  flex: 0 0 calc(25% - 10px);
  box-sizing: border-box;
  justify-content: center;
  border: 2px solid #ccc;
  margin-bottom: 1vh;
  transition: border-color 0.4s;
  border-radius: 1vh;
  overflow: hidden;
  cursor: pointer;
  width: 15vh;
  height: 15vh;
}

.cardmatch {
  display: flex;
  flex: unset;
  box-sizing: border-box;
  justify-content: center;
  border: 2px solid #ccc;
  margin-bottom: 1vh;
  transition: border-color 0.4s;
  border-radius: 1vh;
  overflow: hidden;
  cursor: pointer;
  width: 12vh;
  height: 14vh;
}

.card img {
  width: 300%;
  height: 150%;
}

.flipped {
  transform: rotateY(180deg); /* Flip to show the back */
}

.cardFront,
.cardBack {
  transition: transform 0.6s; /* Smooth transition for flipping effect */
}

.card span {
  max-width: 100%;
  max-height: 100%;
  font-size: x-large;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  height: 100%;
  background-color: #f1f7ed;
  flex-grow: 1;
}

.selected {
  /* Styling for matched cards, perhaps a different border or background */
  border-color: #4CAF50; /* Example: Green border for matched cards */
}

.failed {
  /* Styling for matched cards, perhaps a different border or background */
  border-color: #e91717; /* Example: Green border for matched cards */
}

@media (max-width: 600px) {
  .card span {
    font-size: large;
  }
}

