.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 700px;
}

@media (max-width: 600px) {
  .app {
    position: fixed;
    top: 0;
    left: 0;
    width: -webkit-fill-available;
  }
}

.emplyeeId {
  width: 20px;
}

.app-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: white;
  width: 100%;
}

.dropdown {
  max-width: 80%;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 4;
  width: 100%;
  min-height: 80vh;
  justify-content: space-evenly;
}

.gametable {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: inherit;
}

@media (max-width: 600px) {
  .gametable {
    margin-right: 2px;
    margin-left: 2px;
  }
}

.bottom-banner {
  align-self: flex-end;
  height: 15vh;
  width: 100%;
  background-color: #3b3f48;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}