.match-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 7vh;
  background-color: #fafafa;
  color: rgb(0, 0, 0);
}

.match-column-container {
  display: flex;
  flex-direction: row;
  gap: 5vh;
}

@media (max-width: 600px) {
  .app {
    position: fixed;
    top: 0;
    left: 0;
    width: -webkit-fill-available;
  }
}

.emplyeeId {
  width: 20px;
}

.app-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: white;
  width: 100%;
}

.dropdown {
  max-width: 80%;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 4;
  width: 100%;
  min-height: 80vh;
  justify-content: space-evenly;
}

.gametable {
  display: flex;
  /* flex-direction: column; TODO: THIS SHOULD BE ADDED IN THE MATCH*/
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: inherit;
}

.bottom-banner {
  align-self: flex-end;
  height: 15vh;
  width: 100%;
  background-color: #3b3f48;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}