#root {
  height: 100vh;
}

.logincontainer {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#19f1e4, #0c8871);
}

.loginheader {
  margin-top: 40%;
  flex: 10;
}

.loginbuttoncontainer {
  flex: 3;
}

.loginfooter {
  color: white;
  flex: 4;
}